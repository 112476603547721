import { AnchorRouter, Router } from '@/Components'
import { render as toPlainText } from 'datocms-structured-text-to-plain-text'
import {
  isCode,
  isHeading,
  isLink,
  isParagraph
} from 'datocms-structured-text-utils'
import { Fragment } from 'react'
import { renderNodeRule } from 'react-datocms'
import { sanitizeCMSLinks } from './sanitize'

/**
 * Implements all the custom node types
 * https://github.com/datocms/structured-text/tree/main/packages/utils#typescript-type-guards
 */

export const renderCodeNodeRule = renderNodeRule(isCode, ({ node, key }) => {
  return <div key={key} dangerouslySetInnerHTML={{ __html: node.code }} />
})

export const renderLinkNodeRule = renderNodeRule(
  isLink,
  ({ node, children }) => {
    const target = node.meta?.find((meta) => meta.id === 'target')?.value
    const { toUrl, internal } = sanitizeCMSLinks(node.url as string)

    if (internal) {
      return (
        <Router
          key={node.url}
          href={toUrl}
          className='font-medium text-primary'
        >
          {children}
        </Router>
      )
    }

    return (
      <AnchorRouter
        key={node.url}
        href={toUrl}
        className={'text-primary'}
        target={target}
      >
        {children}
      </AnchorRouter>
    )
  }
)

export const renderOLNodeRule = renderNodeRule(
  isParagraph,
  ({ children, key, ancestors }) => {
    if (
      ancestors[0].type === 'listItem' &&
      ancestors[0].children.length === 1
    ) {
      return <Fragment key={key}>{children}</Fragment>
    }

    return <p key={key}>{children}</p>
  }
)

export function headingToSlug(heading: string): string {
  return heading
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

export const renderHeadingNodeRule = renderNodeRule(
  isHeading,
  ({ adapter: { renderNode }, key, children, node }) => {
    const slug = headingToSlug(toPlainText(node) || '')

    // TODO Add Router here for copying link
    return renderNode(
      `h${node.level}`,
      {
        id: slug,
        key
      },
      children
    )
  }
)
